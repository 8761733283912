import {Actions} from 'vuex-smart-module';
import {VuexMixins} from '@/prototype';
import {IB2bPath} from '@/vuex/Folder/IFolder';
import {FolderGetters, FolderState} from '@/vuex/Folder/store';
import {FolderMutations} from '@/vuex/Folder/Mutations';
import {AxiosError} from 'axios';
import { Toast } from '@/plugin/toast';
import { commit } from 'vuex-smart-module/lib/context';

export class FolderActions extends Actions<FolderState, FolderGetters, FolderMutations, FolderActions> {

    //FOLDERS

    async MOVE_SELECT(id: number){
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.move(this.state.select, id)
            .then(() => {
                this.mutations.DELETE()
                this.mutations.DESELECT()
            }).catch(() => this.mutations.EDIT_DATA(false))
    }

    async COPY_SELECT(id: number){
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.copy(this.state.select, id)
            .then(() => this.dispatch('LOAD_FOLDER_CONTENT')).catch(() => this.mutations.DESELECT())
    }

    async COPY_SELECT_BY_HOTKEY(id: number){
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.copy(this.state.hotKeysContent, id)
            .then(() => this.dispatch('LOAD_FOLDER_CONTENT')).catch(() => this.mutations.DESELECT())
    }

    async FOLDER_DELETE(){
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.delete(this.state.select)
            .then(() => {
                this.mutations.DELETE()
                this.mutations.DESELECT()
            }).catch(() => this.mutations.EDIT_DATA(false))
    }

    async COVER_DELETE(){
      this.mutations.EDIT_DATA(true)
      await VuexMixins.api.folger.deleteCover(this.state.select.folders[0])
          .then(() => {
            this.mutations.COVER_EDIT(null)
            this.mutations.DESELECT();
          }).catch(() => this.mutations.EDIT_DATA(false))
  }

    async LOAD_FOLDER(id?: number | string) {
        await VuexMixins.api.folger.data(
            id === undefined || (typeof id === 'string' && id.length === 0)
                ? this.state.folder.id
                : id
        ).then((res) => this.mutations.LOAD_FOLDER_SUCCESS(res.data.result));
    }

    async LOAD_SELECT_FOLDER(id?: number | string) {
        await VuexMixins.api.folger.data(id || this.state.folder.id)
            .then((res) => this.mutations.LOAD_SELECT_FOLDER_SUCCESS(res.data.result));
    }


    async LOAD_FOLDER_CONTENT(payload?: { id?: number | string; key?: string; filter?: boolean }) {
        payload?.id ? this.mutations.LOAD_FOLDERS(true) : this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.content(payload?.id || this.state.folder.id, payload?.key, payload?.filter)
            .then((res) => {
                this.mutations.LOAD_FOLDERS_SUCCESS(res.data.result.content);
                payload?.id ? this.mutations.LOAD_FOLDERS(false) : this.mutations.EDIT_DATA(false)
            });
    }

    async CREATE_FOLDER(payload: {title: string; id?: number | string}) {
        await VuexMixins.api.folger.create(payload.id || this.state.folder.id, payload.title ? payload.title : this.state.folder.title).then((res) => {
            this.mutations.CREATE_FOLDER(res.data.result)
            this.mutations.DESELECT()
        }).catch(() => this.mutations.EDIT_DATA(false))
    }

    // потому что в модалках не нужен деселкт после создания папки
    async CREATE_FOLDER_MODAL(payload: {title: string; id?: number | string}) {
        await VuexMixins.api.folger.create(payload.id || this.state.folder.id, payload.title ? payload.title : this.state.folder.title).then((res) => {
            this.mutations.CREATE_FOLDER(res.data.result)
            this.LOAD_FOLDER_CONTENT()
        }).catch(() => this.mutations.EDIT_DATA(false))
    }

    async FOLDER_TAGGED() {
        let tagged = 0;
        this.mutations.EDIT_DATA(true)

        Object.keys(this.state.select).forEach((key) => {
            this.state.select[key].map((id) => {
                this.state.content[key].map((item: IFiles | IFolder) => {
                    item.id === id && item.taggeds ? tagged++ : null
                })
            })
        })

        await VuexMixins.api.folger.tagged(this.state.select, !tagged).then(() => {
            this.mutations.EDIT_TAGGED(!tagged)
            this.mutations.DESELECT()
        }).catch(() => this.mutations.EDIT_DATA(false))
    }

    async ADD_TAGGED(payload: {folders: number[]; files: number[]; taggeds: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.updateTagged(payload.folders, payload.files, payload.taggeds)
            .then(() => this.mutations.EDIT_TAGGED(payload.taggeds))
            .catch(() => this.mutations.EDIT_DATA(false))
    }

    async FOLDER_COLOR_EDIT(color: string) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.color(this.state.select.folders, color)
            .then(() => {
                this.mutations.COLOR_EDIT(color)
                this.mutations.DESELECT()
            })
            .catch(() => this.mutations.EDIT_DATA(false));
    }

    async LOAD_RECENT_FOLDERS(payload?: {filter?: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.getRecentFolders(payload?.filter)
            .then((res) => this.mutations.LOAD_RECENT_FOLDERS_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    async LOAD_FAVORITE_FOLDERS(payload?: {filter?: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.getFavoriteFolders(payload?.filter)
            .then((res) => this.mutations.LOAD_FAVORITE_FOLDERS_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    async LOAD_MARKED_FOLDERS(payload: {color: string; filter?: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.getMarked(payload.color, payload.filter)
            .then((res) => this.mutations.LOAD_MARKED_FOLDERS_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    async CHANGE_DESCRIPTION(payload: {id: number; description: string}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.changeDescription(payload.id, payload.description)
            .then((res) => this.mutations.CHANGE_DESCRIPTION_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    // FILES

    async FILES_UPLOAD(file: File[], id?: string | number) {
        const arrFile: File[] = []

        file.forEach(item => arrFile.push(item))

        await VuexMixins.api.folger.fileUpload(file, id || this.state.folder.id).then(res => {
            this.actions.LOAD_FOLDER_CONTENT();
        })
    }

    async FILES_DELETE(id: number | number[]) {
        await VuexMixins.api.folger.filesDelete(id).then(() => this.actions.LOAD_FOLDER_CONTENT());
    }

    async LOAD_RECENT_FILES(payload?: {filter?: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.getRecentFiles(payload?.filter)
            .then((res) => this.mutations.LOAD_RECENT_FILES_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    async LOAD_FAVORITE_FILES(payload?: {filter?: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.getFavoriteFiles(payload?.filter)
            .then((res) => this.mutations.LOAD_FAVORITE_FILES_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    async LOAD_FILE(id: string | number) {
        await VuexMixins.api.folger.getFile(id)
            .then((res) => this.mutations.LOAD_FILE_SUCCESS(res.data.result))
    }

    async LOAD_SELECT_FILE(id?: number | string) {
        await VuexMixins.api.folger.getFile(id || this.state.file.id)
            .then((res) => this.mutations.LOAD_SELECT_FILE_SUCCESS(res.data.result));
    }

    async UPLOAD_COVER(file: File[], id?: string | number) {
      await VuexMixins.api.folger.uploadCover(file, id || this.state.select.folders[0]).then(res => {
        this.mutations.COVER_EDIT(res.data.cover.full_url)
        this.mutations.DESELECT();
    })
  }

    // RENAME

    async RENAME_FILE(payload: {ids: (string | number)[]; title: string | number}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.renameFile(payload.ids, payload.title).then((response) => {
            if (response.status === 200 && response.data?.status?.message === 'Файл с таким названием уже существует') {
                Toast.fire({icon: 'error', title: response.data?.status?.message})
                throw new Error('Файл с таким названием уже существует!');
            }
            this.mutations.RENAME_OBJECT(response.data.result.renamed_files)
            this.mutations.DESELECT()
        }).catch(() => {
            this.mutations.DESELECT()
        })
    }

    async RENAME_FOLDER(payload: {id: string | number; title: string | number}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.renameFolder(payload.id, payload.title).then(() => {
            this.mutations.RENAME_OBJECT({id: payload.id, title: payload.title, type: 'folders'})
            this.mutations.DESELECT()
        }).catch(() => this.mutations.DESELECT())
    }

    //

    async CONTENT_FILE(id: number | string) {
        await VuexMixins.api.folger.contentFile(id)
    }

    // B2B

    async LOAD_CONTENT_B2B(payload: IB2bPath) {
        this.mutations.LOAD_FOLDERS(true)
        await VuexMixins.api.folger.loadB2B(payload).then((res) => {
            this.mutations.LOAD_FOLDERS_SUCCESS(res.data.result.content);
            this.mutations.LOAD_FOLDERS(false)
        }).catch((error: AxiosError) => {
            console.log(error.response);
        })
    }

    //Trash

    async LOAD_TRASH(payload?: {filter?: boolean}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.getTrash(payload?.filter)
            .then((res) => {
                this.mutations.LOAD_TRASH_SUCCESS(res.data.result.content)
            })
        this.mutations.EDIT_DATA(false)
    }

    async LOAD_TRASH_SORT(sort?: string) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.sortTrash(sort)
            .then((res) => this.mutations.LOAD_TRASH_SUCCESS(res.data.result.content))
        this.mutations.EDIT_DATA(false)
    }

    async RESTORE_FROM_TRASH(payload: {folders: number[]; files: number[]}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.restoreFromTrash(payload.folders, payload.files)
        await this.actions.LOAD_TRASH()
        this.mutations.DESELECT()
        this.mutations.EDIT_DATA(false)
    }

    async DELETE_FROM_TRASH(payload: {folders: number[]; files: number[]}) {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.deleteFromTrash(payload.folders, payload.files)
        await this.actions.LOAD_TRASH()
        this.mutations.DESELECT()
        this.mutations.EDIT_DATA(false)
    }

    async DELETE_ALL_FROM_TRASH() {
        this.mutations.EDIT_DATA(true)
        await VuexMixins.api.folger.deleteAllFromTrash()
        await this.actions.LOAD_TRASH()
        this.mutations.DESELECT()
        this.mutations.EDIT_DATA(false)
    }

    // Сортировка в табличном виде
    async SORT_TABLE(payload: 'server' | 'local') {
        this.mutations.EDIT_DATA(true);
        if(payload === 'server') {
            await VuexMixins.api.folger.tableSorting(this.state.folder.id)
              .then((res) => this.mutations.SORT_TABLE_SUCCESS(res.data.result.content))
        } else {
            this.mutations.SORT_LOCAL()
        }
        this.mutations.EDIT_DATA(false)
    }

    // Public Link
    async LOAD_PUBLIC_LINK_FOR_FOLDER(payload: {id: number; permissions: string}) {
        await VuexMixins.api.folger.publicLink(payload.id, payload.permissions)
            .then((res) => this.mutations.LOAD_PUBLIC_LINK_FOR_FOLDER_SUCCESS(res.data.result.link))
    }

    // Size Selected Folders And Files
    async LOAD_SIZE(payload: {folders?: number[]; files?: number[]}) {
        await VuexMixins.api.folger.getSize(payload.folders, payload.files)
            .then((res) => this.mutations.LOAD_SIZE_SUCCESS(res.data.result.size))
    }

    // Permissions Selected Folders And Files
    async LOAD_PERMISSIONS_SELECTED_ELEMENTS(payload: {folders?: number[]; files?: number[]}) {
        await VuexMixins.api.folger.getSelectedPermissions(payload.folders, payload.files)
            .then((res) => this.mutations.LOAD_PERMISSIONS_SELECTED_ELEMENTS_SUCCESS(res.data.result.permissions))
    }

    async LOAD_FOLDER_CONTENT_SEARСH(searchString: string) {
        this.mutations.LOAD_FOLDERS(true);
        await VuexMixins.api.search.searchAll(searchString)
          .then((res) => {
              this.mutations.LOAD_FOLDERS_SUCCESS_SEARCH(res.data.result.body.content);
              this.mutations.LOAD_FOLDERS(false);
          })
          .catch(() => this.mutations.LOAD_FOLDERS(false))
        ;
    }
}
