import {Getters, Mutations, Module} from 'vuex-smart-module';
import {IContext} from '@/interface/IContext';
import * as jquery from 'jquery';
import {IFiles} from '@/vuex/Folder/IFolder';

class ContextMenuState {
    context: IContext = {
        x: 0,
        y: 0,
        show: false,
        revealContextMenu: false,
        type: null,
        item: {
            id: 1,
            title: '',
            'created_at': '',
            color: '',
            description: '',
            cover_url:'',
            permissions: 0,
            taggeds: false,
            marked: '',
            parent: 1,
            size: 0
        },
    };
}

class ContextMenuMutations extends Mutations<ContextMenuState> {
    public SHOW_CONTEXT_MENU(payload: { event: MouseEvent; type: string; item?: IFolder | IFiles}) {

        this.state.context = {
            x: payload.event.clientX - 35 - (document.querySelector('.v-navigation-drawer__content')?.clientWidth as number),
            y: payload.event.pageY - 128,
            revealContextMenu: false,
            type: payload.type,
            show: true,
            item: payload.item,
        };

        const context = document.getElementById('Context') as HTMLElement
        const windwos = document.querySelector('.page__content')?.clientWidth as number
        const heightZone: number =  window.innerHeight
        const menu = document.getElementById('Context') as Element;
        let clientHeight = 0;
        (document.querySelector('.context__body')?.childNodes.forEach((item) => (item as HTMLElement).classList.value === 'list' ? clientHeight += 40 : clientHeight += 67 ))

        let x = payload.event.clientX
        let y = payload.event.pageY - 80

        if(((menu.clientWidth || 194) + x + 35) > windwos){
            x -= menu.clientWidth || 194;
        }
        if(x < 0){
            x -= (menu.clientWidth + x - windwos + 10);
        }
        if((clientHeight + y + 100) > heightZone){
            y -= clientHeight;
        }

        context.style.top = y + 'px'
        context.style.left = x + 'px'

        jquery('#Context').stop(true, true).slideDown(200).css({'display':'none'})
        jquery('#Context').stop(true, true).slideDown(200).css({'display':'block'})
    }
    public CLOSE_CONTEXT_MENU() {

        jquery('#Context').css({'display':'none'})
        jquery('.color-arr').css({'display': 'none'})
        this.state.context.show = false;
    }

    public REVEAL_CONTEXT_MENU(value: boolean) {
        this.state.context.revealContextMenu = value;
    }
}

class ContextMenuGetters extends Getters<ContextMenuState> {
    public GET_SHOW_MENU() {
        return this.state.context;
    }
}

export const ContextMenu = new Module({
    state: ContextMenuState,
    mutations: ContextMenuMutations,
    getters: ContextMenuGetters,
});
