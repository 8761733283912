import {$http} from '@/main';
import {AxiosPromise} from 'axios';
import {IB2bPath, ISelect} from '@/vuex/Folder/IFolder';

interface IFolderApi {
    filesDownload(select: ISelect): AxiosPromise;

    tree(id: string | number): AxiosPromise;

    content(id: string | number, key?: string, filter?: boolean): AxiosPromise;

    tableSorting(id: string | number, order: string, sorting: string): AxiosPromise;

    data(id: string | number): AxiosPromise;

    loadB2B(payload: IB2bPath): AxiosPromise;

    breadcrumbs(id: string | number): AxiosPromise;

    fileUpload(file: File[], id: string | number): AxiosPromise;

    filesDelete(id: number | number[]): AxiosPromise;

    tagged(select: ISelect, taggeds: boolean ): AxiosPromise;

    updateTagged(folders: number[], files: number[], taggeds: boolean): AxiosPromise;

    count(id: number | string): AxiosPromise;

    color(folder: number[], color: string): AxiosPromise;

    delete(select: ISelect): AxiosPromise;

    // eslint-disable-next-line @typescript-eslint/camelcase
    move(select: ISelect, where_id: number): AxiosPromise;

    // eslint-disable-next-line @typescript-eslint/camelcase
    copy(select: ISelect, where_id: number): AxiosPromise;

    getRecentFolders(filter?: boolean): AxiosPromise;

    getRecentFiles(filter?: boolean): AxiosPromise;

    getMarked(color: string, filter?: boolean): AxiosPromise;

    getFavoriteFolders(filter?: boolean): AxiosPromise;

    getFavoriteFiles(filter?: boolean): AxiosPromise;

    renameFile(ids: (string | number)[], title: string | number): AxiosPromise;

    getTrash(filter?: boolean): AxiosPromise;

    restoreFromTrash(folders: number[], files: number[]): AxiosPromise;

    deleteFromTrash(folders: number[], files: number[]): AxiosPromise;

    deleteAllFromTrash(): AxiosPromise;

    publicLink(id: number, permissions: string): AxiosPromise;

    getSize(folders?: number[], files?: number[]): AxiosPromise;

    changeDescription(id: number, description: string): AxiosPromise;
}

class FolderApi implements IFolderApi {
    data(id: string | number) {
        return $http.post('/folder/get', {id})
    }

    content(id: string | number, key?: string, filter?: boolean) {
        return $http.post('/folder/content', {
            id,
            key,
            'sort_order': localStorage.getItem('sort_order'),
            sorting: localStorage.getItem('sorting')
        })
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    tableSorting(id: string | number): AxiosPromise {
        return $http.post('/folder/content', {
            id,
            'sort_order': localStorage.getItem('sort_order'),
            sorting: localStorage.getItem('columnName')
        })
    }

    tree(id: string | number) {
        return $http.post('/folder/tree', {id})
    }

    create(id: string | number, title: string) {
        return $http.post('/folder/create', {id, title})
    }

    filesDownload(select: ISelect): AxiosPromise {
        return $http.post('/folder/download', select)
    }

    breadcrumbs(id: string | number): AxiosPromise {
        return $http.post('/folder/crumbs', {id})
    }

    loadB2B(payload: IB2bPath): AxiosPromise {
        return $http.post(`/b2b/clients/${payload.name}`, {key: payload.key})
    }

    fileUpload(file: File[], id: string | number): AxiosPromise {
        const formData = new FormData()

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        formData.append('id', id);

        file.forEach((items, idx) => {
            formData.append(idx.toString(), items, items.name)
        })

        return $http.post('/file/upload', formData, {responseType: 'arraybuffer'})
    }

    uploadCover(file: File[], id: string | number): AxiosPromise {
      const formData = new FormData()

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      formData.append('folder_id', id);
      formData.append("cover", file[0], file[0].name);

      return $http.post('/folder/cover', formData)
  }

    filesDelete(id: number | number[]) {
        return $http.post('/file/delete', {
            id: [id]
        })
    }

    tagged(select: ISelect, taggeds: boolean): AxiosPromise {
        return $http.post('/folder/update_tagged', {
            folders: select.folders,
            files: select.files,
            taggeds: taggeds
        })
    }

    updateTagged(folders: number[], files: number[], taggeds: boolean): AxiosPromise {
        return $http.post('/folder/update_tagged', {folders, files, taggeds})
    }

    count(id: number | string): AxiosPromise {
        return $http.post('/folder/getCount', {id})
    }

    color(folder: number[], color: string): AxiosPromise {
        return $http.post('/folder/change_color', {
            id: folder, color
        })
    }
    delete(select: ISelect): AxiosPromise {
        return $http.post('/folder/delete', select)
    }

    deleteCover(id: number): AxiosPromise {
      return $http.delete('/folder/cover', {data:{folder_id:id}})
  }

    // eslint-disable-next-line @typescript-eslint/camelcase
    move(select: ISelect, where_id: number ): AxiosPromise {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return $http.post('/folder/move', {...select, where_id})
    }

    // eslint-disable-next-line @typescript-eslint/camelcase
    copy(select: ISelect, where_id: number): AxiosPromise {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return $http.post('/folder/copy', {...select, where_id})
    }

    getRecentFolders(filter?: boolean): AxiosPromise {
        return $http.post('/folder/getRecent', {
            'sorting': localStorage.getItem('sort_order')
        })
    }

    getRecentFiles(filter?: boolean): AxiosPromise {
        return $http.post('/file/getRecent', {
            'sorting': localStorage.getItem('sort_order')
        })
    }

    getFavoriteFolders(filter?: boolean): AxiosPromise {
        return $http.post('/folder/getTaggeds', {
            'sorting': localStorage.getItem('sort_order')
        })
    }

    getFavoriteFiles(filter?: boolean): AxiosPromise {
        return $http.post('/file/getTaggeds', {
            'sorting': localStorage.getItem('sort_order')
        })
    }

    getMarked(color: string, filter?: boolean): AxiosPromise {
        return $http.post('/folder/getMarked', {
            color,
            'sorting': localStorage.getItem('sort_order')
        })
    }

    renameFolder(id: string | number, title: string | number): AxiosPromise {
        return $http.post('/folder/rename', {id, title})
    }

    renameFile(ids: (string | number)[], title: string | number): AxiosPromise {
        return $http.post('/file/rename', {ids, title})
    }

    getFile(id: string | number): AxiosPromise {
        return $http.post('/file/get', {id})
    }

    contentFile(id: number | string): AxiosPromise {
        return $http.post('/file/fileContent', {id})
    }

    getTrash(filter?: boolean): AxiosPromise {
        return $http.post('/folder/getTrash', {
            'sorting': localStorage.getItem('sort_order')
        })
    }

    sortTrash(sort?: string): AxiosPromise {
        return $http.post('/folder/getTrash', {sort})
    }

    restoreFromTrash(folders: number[], files: number[]): AxiosPromise {
        return $http.post('/folder/restoreFromTrash', {folders, files})
    }

    deleteFromTrash(folders: number[], files: number[]): AxiosPromise {
        return $http.post('/folder/deleteFromTrash', {folders, files})
    }

    deleteAllFromTrash(): AxiosPromise {
        return $http.post('/folder/deleteAllFromTrash')
    }

    publicLink(id: number, permissions: string): AxiosPromise {
        return $http.post('/folder/publicLink', {id, permissions})
    }

    getSize(folders?: number[], files?: number[]): AxiosPromise {
        return $http.post('/folder/getSize', {folders, files})
    }

    getSelectedPermissions(folders?: number[], files?: number[]): AxiosPromise {
        return $http.post('/folder/getSelectedPermissions', {folders, files})
    }

    changeDescription(id: number, description: string): AxiosPromise {
        return $http.post('/folder/changeDescription', {id, description})
    }
}

export default new FolderApi();
